import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import PageBuilder from '../components/pageBuilder'
import Helmet from 'react-helmet'

export const query = graphql`
  query ProjektePageQuery {
    projektePage: sanityPage(slug: { current: { eq: "projekte" } }) {
      id
      title
      ...PageBuilder
    }
    categories: allSanityCategory {
      nodes {
        title
        filterOrder
      }
    }
    statuses: allSanityStatus {
      nodes {
        title
        filterOrder
      }
    }
    area: allSanityArea {
      nodes {
        title
        filterOrder
      }
    }
  }
`

class ProjektePage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { data, errors } = this.props
    const pageData = data && data.projektePage
    const categories = data.categories.nodes
    const areas = data.area.nodes
    const statuses = data.statuses.nodes
    const searchData = {
      categories,
      areas,
      statuses,
    }

    if (errors) {
      return <GraphQLErrorList errors={errors} />
    }

    return (
      <>
        {pageData && pageData.title && <SEO title={pageData.title} />}
        <Helmet>
          <body className="has-projects-filter is-page-projekte" />
        </Helmet>
        {pageData && searchData && (
          <PageBuilder
            pageBuilder={pageData.pageBuilder}
            _rawPageBuilder={pageData._rawPageBuilder}
            searchData={searchData}
          />
        )}
      </>
    )
  }
}

export default ProjektePage
